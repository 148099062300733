
  import { computed, defineComponent, onMounted, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import Multiselect from '@vueform/multiselect';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'ire-industrial-streets-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const submitSizeButton = ref<HTMLButtonElement | null>(null);
      const submitPropertyAgeButton = ref<HTMLButtonElement | null>(null);
      const floorTypes = ref<{ titleAr: string; id: number }[]>([]);
      const direction = ref<{ titleAr: string; id: number }[]>([]);
      const selectedPropertyTypesId = ref<number[]>([]);
      const dublicatedError = ref({ isActive: false, index: -1 });
      const requierdFieldError = ref({ isActive: false, index: -1 });

      const fields = ref([
        {
          propertyTypeId: 0,
          floorTypeId: 0,
          directionId: 0,
          decreasePercentage: '',
          value: '',
        },
      ]);
      const { can } = useAbility();

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('createNewIreStreet'), [
          translate('ire'),
        ]);
      });

      watch(fields.value, () => {
        const isValid = checkDublicatedField();
        if (isValid !== -1) {
          dublicatedError.value.index = isValid;
          dublicatedError.value.isActive = true;
        } else {
          dublicatedError.value = { isActive: false, index: -1 };
        }
      });

      const addNewField = () => {
        fields.value.push({
          propertyTypeId: 0,
          floorTypeId: 0,
          directionId: 0,
          decreasePercentage: '',
          value: '',
        });
      };
      const removeField = (index: number) => {
        fields.value.splice(index, 1);
      };

      const handleSelectedPropertyType = async (index: number) => {
        await store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');

        if (selectedPropertyTypesId.value) {
          await store.dispatch(Actions.GET_IRE_COMMON_FLOOR_TYPES_ALL, {
            type: 5,
            propertyTypeId: fields.value[index].propertyTypeId,
          });
          floorTypes.value[index] = store.getters.getAllIreCommonFloorTypes;
        }

        await store.dispatch(
          Actions.REMOVE_BODY_CLASSNAME,
          'page-loading-over'
        );
      };

      const handleSelectedFloorTypeId = async (index: number) => {
        await store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');
        await store.dispatch(Actions.GET_IRE_COMMON_PROPERTY_DIRECTION_ALL, {
          type: 5,
          floorTypeId: fields.value[index].floorTypeId,
        });
        direction.value[index] = store.getters.getAllIreCommonPropertyDirection;

        await store.dispatch(
          Actions.REMOVE_BODY_CLASSNAME,
          'page-loading-over'
        );
      };

      const checkDublicatedField = () => {
        for (let i = 0; i < fields.value.length; i++) {
          for (let j = i + 1; j < fields.value.length; j++) {
            if (
              JSON.stringify(fields.value[i]) ===
              JSON.stringify(fields.value[j])
            ) {
              return i + 1;
            }
          }
        }
        return -1;
      };

      const checkRequiredField = () => {
        for (let index = 0; index < fields.value.length; index++) {
          const field = fields.value[index];

          if (
            field.propertyTypeId === 0 ||
            field.floorTypeId === 0 ||
            field.directionId === 0 ||
            field.value === '' ||
            field.decreasePercentage === ''
          ) {
            requierdFieldError.value = { isActive: true, index };
            return false;
          }
        }

        requierdFieldError.value = { isActive: false, index: -1 };

        return true;
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const createdStreet = ref<any | null>(null);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const ireStreetSchema = Yup.object().shape({
        sort: Yup.number()
          .required(() => translate('SORT_IS_REQUIRED_FIELD'))
          .label('sort'),
        titleEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('English Name'),
        titleAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('Arabic Name'),
      });
      await store.dispatch(Actions.GET_ALL_IRE_COMMON_AREAS, { type: 5 });

      const areas = computed(() => store.getters.ireCommonAreasAllList);
      const selectedArea = ref<number>(areas.value[0].id);

      // const sizeOptions = store.getters.ireV2Size.map((p) => ({
      //   id: p.id,
      //   isEnabled: false,
      //   isBase: false,
      //   title: p.title ? p.title : p.titleAr,
      //   percentage: null,
      // }));

      // const propertyAgeOptions = store.getters.ireV2PropertyAge.map((p) => ({
      //   id: p.id,
      //   title: p.title ? p.title : p.titleAr,
      //   price: null,
      // }));

      // let sizeDescOptions: any = [];

      // console.log(sizeDescOptions);

      const onSubmitCreate = async (values) => {
        const isThereDublicated = checkDublicatedField();
        const isFieldsValid = checkRequiredField();
        if (isThereDublicated === -1 && isFieldsValid) {
          if (submitButton.value) {
            // eslint-disable-next-line
            submitButton.value!.disabled = true;
            submitButton.value.setAttribute('data-kt-indicator', 'on');
          }
          const payload = {
            areaId: selectedArea.value,
            titleAr: values.titleAr,
            titleEn: values.titleEn,
            sort: values.sort,
            type: 5,
            ireCommonOptions: fields.value,
          };
          const result = await store.dispatch(
            Actions.CREATE_IRE_COMMON_STREET,
            payload
          );
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];

          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          } else {
            createdStreet.value = result;

            Swal.fire({
              text: translate('SUCCESSFULLY_CREATED'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            }).then(async () => {
              router.push({
                name: 'ire-industrial-streets-editing',
                params: { id: createdStreet.value.id },
              });

              //Deactivate indicator
              submitButton.value?.removeAttribute('data-kt-indicator');
              // eslint-disable-next-line
              submitButton.value!.disabled = false;
            });
          }
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      await store.dispatch(Actions.GET_IRE_COMMON_PROPERTY_TYPE_ALL, {
        type: 5,
      });

      return {
        direction,
        floorTypes,
        propertyTypes: computed(
          () => store.getters.getAllIreCommonPropertyTypes
        ),
        areas,
        onSubmitCreate,
        submitButton,
        translate,
        ireStreetSchema,
        goBack,
        can,
        selectedArea,
        createdStreet,
        submitPropertyAgeButton,
        submitSizeButton,
        selectedPropertyTypesId,
        addNewField,
        removeField,
        handleSelectedPropertyType,
        handleSelectedFloorTypeId,
        fields,
        dublicatedError,
        requierdFieldError,
      };
    },
  });
